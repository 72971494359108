import React, { useState } from 'react';
import './App.css';
import {
  Form, Input, Button, FormFeedback,
} from 'reactstrap';

function App() {
  const [input, setInput] = useState("");
  const [validationObj, setValidationObj] = useState ({
    isValidated : false,
    isNotValidated : false
  }); 
  const [antwort, setAntwort] = useState(null);
  const [secondButton, setSecondButton] = useState(null);
  const [confirmationMessage, setConfirmationMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  const reset = () => ((setAntwort(null), setSecondButton(null, setConfirmationMessage(null), setError(null))));

  const changeHandler = (e) => {
    let val = e.target.value;
    if (!Number.isInteger(Number(val))) {
      setValidationObj({isNotValidated : true, isValidated : false});
    }
    if (val.length > 10) {
      setValidationObj({isNotValidated : true, isValidated : false});
    }
    if (val == "") {
      setValidationObj({isValidated : false, isNotValidated : false});
    }
    if (Number.isInteger(Number(val)) && val.length <= 10 && val != "") {
      setValidationObj({isValidated : true, isNotValidated : false});
    }
    setInput(e.target.value);
  };

  const submitHandler1 = (e) => {
    e.preventDefault();
    if (!Number.isInteger(Number(input)) || input == "" || input.length > 10) {
      setError(true);
      setErrorMessage("Sie haben keine gültige Zahl eingegeben.")
    }
    else {
      reset();
      setIsLoading(true);
    
      fetch('https://numbers.hafnermassage.com/vergleichen.php', {
        method: 'POST',
        body: 'wert=' + encodeURIComponent(input),
        headers: {
          'Content-type': 'application/x-www-form-urlencoded'
        }
        })
        .then(response => {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error('Seite nicht gefunden');
          }
        })    
        .then(data => (setAntwort(data), setSecondButton(data), setIsLoading(false)))
        .catch(error => (setError(true), setIsLoading(false), setErrorMessage(error.toString())))
    };
  };

  const submitHandler2 = (e) => {
    e.preventDefault();
    setSecondButton(null);
    setConfirmationMessage(null);
    setIsLoading(true);
    setError(null);

    fetch('https://numbers.hafnermassage.com/entfernen.php', {
      method: 'POST',
      body: 'wert=' + encodeURIComponent(input),
      headers: {
        'Content-type': 'application/x-www-form-urlencoded'
      }
      })
      .then(response => {
        if (response.ok) {
          return response.text();
        } else {
          throw new Error('Seite nicht gefunden');
        }
      })    
      .then(data => (setConfirmationMessage(data), setIsLoading(false)))
      .catch(error => (setError(true), setIsLoading(false), setErrorMessage(error.toString())))

  };

  const submitHandler3 = (e) => {
    e.preventDefault();
    setSecondButton(null);
    setConfirmationMessage(null);
    setIsLoading(true);
    setError(null);

    fetch('https://numbers.hafnermassage.com/speichern.php', {
      method: 'POST',
      body: 'wert=' + encodeURIComponent(input),
      headers: {
        'Content-type': 'application/x-www-form-urlencoded'
      }
      })
      .then(response => {
        if (response.ok) {
          return response.text();
        } else {
          throw new Error('Seite nicht gefunden');
        }
      })    
      .then(data => (setConfirmationMessage(data), setIsLoading(false)))
      .catch(error => (setError(true), setIsLoading(false), setErrorMessage(error.toString())))

  };

  return (
    <div className = "App">
    
      <h1>Zahlen speichern</h1>
      <Form>            
        <p><Input 
          valid={validationObj.isValidated == true} 
          invalid={validationObj.isNotValidated == true} 
          type="text" 
          inputmode = "numeric" 
          pattern = "[0-9]*"
          value={input} 
          placeholder="Hier eingeben" 
          onFocus={reset} 
          onChange={changeHandler}
        />
        <FormFeedback className = "feedback" invalid>Ganzzahl (max. 10 Ziff.) erforderlich</FormFeedback></p>   
      </Form>

      <div className = "buttonblock">
        <p><Button color = "primary" block onClick={submitHandler1}>Überprüfen</Button></p>
      </div>     

      <div>
        {
          (antwort == true) ? (
            <p>Die Zahl ist schon gespeichert.</p>
          ) : (antwort == false) ? (
            <p>Die Zahl ist noch nicht gespeichert.</p>
          ) : null
        }
      </div>

      <div className = "buttonblock">
        {
          (secondButton == true) ? (
            <div>
              <Form>
                <p><Button color = "primary" block onClick={submitHandler2}>Löschen</Button></p>
              </Form>
            </div>
          ) : (secondButton == false) ? (
            <div>
              <Form>
                <p><Button color = "primary" block onClick={submitHandler3}>Speichern</Button></p>
              </Form>
            </div>
          ) : null
        }
      </div>
      
      <div>
        {
          (confirmationMessage) ? (
            <p>{confirmationMessage}</p>
          ) : null
        }
      </div>

      <div>
        {
          (isLoading == true) ? (
            <p>...bitte warten...</p>
          ) : null
        }
      </div>

      <div>
        {
          (error) ? (
            <p>Fehlermeldung: {errorMessage}</p>
          ) : null
        }
      </div>
    
    </div>
  );
}

export default App;